<i18n src="@/i18n/http.json"></i18n>

<template lang="pug">
    .subscriber-verification.view
        section.centered
            .section-content-wrapper
                h1 Подтверждение номера
                .subscriber-verification-form
                    el-form(:model="subscriber_verification_form", @keydown.enter.native.prevent="verifySubscriber", :rules="subscriber_verification_form_rules", label-position="top", ref="subscriber_verification_form", autocomplete="off")
                        el-form-item.code-input(prop="verification_code", type="text", :label="verification_code_label")
                            el-input(v-model="subscriber_verification_form.verification_code", :disabled="!verification_code_is_valid" ref="verification_form_verification_code")
                        el-form-item
                            el-button(v-if="verification_code_is_valid" @click="verifySubscriber", type="primary") Подтвердить
                            el-button(v-else @click="startNewVerification", type="primary") Получить новый код
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {GET_UNVERIFIED_SUBSCRIBER, SUBSCRIBER_NEW_VERIFICATION, VERIFY_SUBSCRIBER} from "@/store/types"

const default_subscriber_verification_form = () => {
    return {
        verification_code: '',
    }
}

export default {
    metaInfo() {
        return {
            title: this.title
        }
    },
    data() {
        return {
            verification_code_is_valid: false,
            verification_code_valid_until: '',
            verification_code_validity_calculation_timeout: undefined,
            subscriber_verification_form: default_subscriber_verification_form(),
            subscriber_verification_form_rules: {
                verification_code: [
                    {required: true, message: 'Укажите полученный код', trigger: 'blur'},
                ],
            },
        }
    },
    props: [
        'verification_uid'
    ],
    computed: {
        ...mapGetters([
            'subscriber',
        ]),
        title() {
            return 'Подтверждение номера телефона';
        },
        verification_code_label() {
            if (this.subscriber.verification_uid) {
                return 'Полученный код ' + (this.subscriber.verification_code ?? '');
            }

            return 'Полученный код';
        },
    },
    created() {
        this.GET_UNVERIFIED_SUBSCRIBER(this.verification_uid).then(() => {
            this.startNewVerification();
        }).catch((e)=>{
            this.$router.push({name: 'subscriber.login'});
        });
    },
    updated() {
        this.$refs.verification_form_verification_code.focus();
    },
    beforeRouteLeave(to, from, next) {
        window.clearTimeout(this.verification_code_validity_calculation_timeout)

        next()
    },
    methods: {
        ...mapActions([
            GET_UNVERIFIED_SUBSCRIBER,
            SUBSCRIBER_NEW_VERIFICATION,
            VERIFY_SUBSCRIBER,
        ]),
        resetSubscriberForm() {
            window.clearTimeout(this.verification_code_validity_calculation_timeout)
            this.subscriber_verification_form = default_subscriber_verification_form()
        },
        startNewVerification() {
            this.SUBSCRIBER_NEW_VERIFICATION(this.subscriber).then(() => {
                this.resetSubscriberForm()
                this.calculateVerificationCodeValidityTime()
            }).catch((error) => {
                if (error.response.data.errors) {
                    this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                        return this.$t(message)
                    }))

                    this.$router.push({name: 'subscriber.login'})
                }
            })
        },
        calculateVerificationCodeValidityTime() {
            this.verification_code_valid_until = this.$moment.utc(this.subscriber.verification_code_valid_until).fromNow(true)
            this.verification_code_is_valid = this.$moment.utc(this.subscriber.verification_code_valid_until).diff(this.$moment.utc()) > 0
            this.verification_code_validity_calculation_timeout = setTimeout(this.calculateVerificationCodeValidityTime, 1000)
        },
        verifySubscriber() {
            this.$refs['subscriber_verification_form'].validate((valid) => {
                if (valid) {
                    this.VERIFY_SUBSCRIBER({...this.subscriber, ...this.subscriber_verification_form}).then(() => {
                        if (window.gtag !== undefined) {
                            gtag('event', 'confirm_phone', {
                                'event_category': 'onboarding'
                            })
                            console.log('GA goal reached: confirm_phone')
                        }

                        if (window.ym !== undefined) {
                            window.ym(74625946, 'reachGoal', 'confirm_phone')
                            console.log('YM goal reached: confirm_phone')
                        }

                        this.$router.push({name: 'subscriptions'})
                    }).catch((error) => {
                        if (error.response.data.errors) {
                            this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                                return this.$t(message)
                            }))
                        }
                    })
                } else return false;
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.subscriber-verification {
    section {
        background: $color--background;
        color: $color--black;
    }

    &-form {
        .el-form {
            .code-input {
                display: inline-block;
                text-indent: 0;
                overflow: hidden;
                width: 50%;
            }

            .terms {
                .description {
                    //@include font--regular;

                    line-height: 1.5em;
                    padding-bottom: $padding18;
                }
            }

            @media (max-width: $max-width--tablets) {
                .code-input {
                    width: 100%;
                }
            }
        }
    }
}
</style>
